import React from 'react';
import { NotificationPanelStateProvider } from './notification-panel-context';
import { NotificationPanelContent } from './notification-panel-content';
import { PopoverContentProps } from '@radix-ui/react-popover';

interface Props {
  children: (unreadCount: number) => React.ReactNode;
  side?: PopoverContentProps['side'];
  useGetNotificationsQuery: any;
  useUpdateNotificationMutation: any;
  useMarkAllAsReadMutation: any;
  user_id: string;
  showSettings?: boolean;
  onSettings?: () => void;
}
export const NotificationPanel = React.memo((props: Props) => {
  return (
    <NotificationPanelStateProvider>
      <NotificationPanelContent {...props} />
    </NotificationPanelStateProvider>
  );
});

NotificationPanel.displayName = 'NotificationPanel';
